<div class="tabs dashboard-component-tabs is-boxed is-small is-marginless">
  <ul>
    <li class="is-active">
      <a><span i18n>Search</span></a>
    </li>
  </ul>
</div>


<div class="control has-icons-left has-icons-right">
  <input class="input is-small input-search" type="text" [ngModel]="objectIDsString" 
    placeholder="Enter plot IDs..." i18n-placeholder="Enter plot IDs..."
    (ngModelChange)="setObjectIDsString($event)">
  <span class="icon is-small is-left">
    <i-feather name="search" class="icon-smaller"></i-feather>
  </span>
  <div class="search-footer right-align has-shadow">
    <button *ngIf="!loading; else loadingBtn" class="button is-light is-small" (click)="requestPlots()">
      <strong i18n>Apply filters</strong>
    </button>
    <ng-template #loadingBtn>
      <button  class="button is-light is-small" disabled>
        <div class="loader"></div>&nbsp;
        <strong i18n>Apply filters</strong>
      </button>
    </ng-template>
    &nbsp;
    <button class="button is-light is-small" (click)="resetFilters()" [disabled]="loading">
      <ng-container i18n>Reset filters</ng-container>
    </button>
  </div>
</div>

<div class="search-results">
  <div class="message is-small" *ngIf="plotIDs && plotIDs.length > 0 && !loading">
    <div class="columns">
      <div class="column is-italic">
        <div class="scrollable-list">
          <span i18n>Found following plots and added them to Dashboard:</span> 
          <span *ngFor="let id of plotIDs; let i = index" style="user-select: text;">
            {{id}}<br *ngIf="(i + 1) % 5 === 0">
          </span>
        </div>
      </div>
    </div>
  </div> 

  <div class="message is-small" *ngIf="notFoundIDs && notFoundIDs.length > 0 && !loading">
    <div class="columns">
      <div class="column is-italic has-text-danger"> 
        <div class="scrollable-list">
          <span i18n>Following plot IDs could not be found:</span>
          <span *ngFor="let id of notFoundIDs; let i = index" style="user-select: text;">
            {{id}}<br *ngIf="(i + 1) % 5 === 0">
          </span>
        </div>
      </div>
    </div>
  </div> 
</div>
