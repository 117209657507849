<div class="modal is-active">
    <div (click)="cancel()" class="modal-background"></div>
    <swiper-container appSwiper class="swiper" *ngIf="photoData && photoData.length > 0" [config]="swiperOptions" zoom-max-ratio="3"
        zoom-min-ratio="1">
        <swiper-slide lazy="true" *ngFor="let photo of photoData; let i = index">
            <div class="column has-text-weight-semibold has-text-right">
                <span class="tag is-info is-medium" *ngIf="photo.source === 'SFB'; else faniTag">sFB</span>
                <ng-template #faniTag> 
                  <span class="tag is-link is-medium">FANi</span>
                </ng-template>
              </div>
              <div class="swiper-zoom-container">
                <img loading="lazy" src="{{ photo.imageId | authImage: false:rotated | async }}">
            </div>
        </swiper-slide>
    </swiper-container>


    <div class="buttons has-addons is-centered are-small">
        <button class="button is-black is-small" (click)="rotatePhoto(90)">
            <strong i18n>Rotate</strong>
            <span class="icon is-small">
                <i-feather name="rotate-cw" class="icon-smaller"></i-feather>
            </span>
        </button>

        <button class="button is-black is-small" (click)="rotatePhoto(180)">
            <strong i18n>Flip</strong>
            <span class="icon is-small">
                <i-feather name="refresh-cw" class="icon-smaller"></i-feather>
            </span>
        </button>

        <button class="button is-black is-small" (click)="rotatePhoto(270)">
            <strong i18n>Rotate</strong>
            <span class="icon is-small">
                <i-feather name="rotate-ccw" class="icon-smaller"></i-feather>
            </span>
        </button>

        <button class="button is-black is-small" (click)="rotatePhoto(360)">
            <strong i18n>Reset</strong>
            <span class="icon is-small">
                <i-feather name="stop-circle" class="icon-smaller"></i-feather>
            </span>
        </button>

    </div>
    <button class="modal-close is-large" aria-label="close" (click)="cancel()"></button>
</div>
