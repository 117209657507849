import {Pipe, PipeTransform} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, switchMap, firstValueFrom} from 'rxjs';
import {amsSnippetsUrl} from "../events";

@Pipe({
  standalone: true,
  name: 'authSnippet'
})
export class AuthSnippetPipe implements PipeTransform {

  constructor(
    private http: HttpClient
  ) {
  }

  getSnippetData(resourceToken: string, small: boolean): Observable<any> {
    const size = small ? '?size=650x500' : '?size=960x640';

    return amsSnippetsUrl.pipe(
      switchMap((url: string) => this.http.get(url + '/resource/' + resourceToken + size, {responseType: 'blob'}))
    ) as Observable<any>;
  }


  /**
   * Transforms an image ID into a data URL string.
   * Fetches image data as a Blob from the server and converts it to a base64-encoded data URL.
   * If the fetch operation fails, returns a fallback image URL.
   *
   * @param id - The unique identifier for the image to be fetched.
   * @param small - A boolean indicating whether to fetch a smaller version of the image.
   * @returns A Promise that resolves to a base64-encoded data URL of the image or a fallback image URL.
   */

  async transform(id: string, small: boolean): Promise<string> {
    try {
      const imageBlob = await firstValueFrom(this.getSnippetData(id, small));
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(imageBlob);
      });
    } catch {
      return 'assets/img/fallback.png';
    }
  }

}
