<div class="tabs dashboard-component-tabs is-boxed is-small is-marginless" *ngIf="userIsCustomer">
  <ul>
    <li class="is-active">
      <a><span i18n>Satellite</span></a>
    </li>
  </ul>
</div>

<!-- <div class="gallery">
    <img *ngFor="let image of snippets" src="{{ image.resourceToken | authSnippet: true | async }}" class="gallery-item"
    (click)="toggleSelect(image)"  [ngClass]="{'selected': selectedImage === image}">
    <p class="image-date has-text-centered">{{ image.date | date:'dd MMM yyyy' }}</p>
</div> -->

<div class="gallery scrollable-content" *ngIf="(snippets && snippets.length > 0) || reloadingSnippets">
  <div class="columns has-background-grey-lighter">
    <div class="column is-size-smaller">
      <label class="checkbox" style="padding-left: 5px;">
        <input type="checkbox" [(ngModel)]="hideCloudImages" (change)="this.querySnippetData(true)" />
        &nbsp;<span i18n>Hide cloud images</span>
      </label>
    </div>
  </div>
  <div class="snippets" [ngClass]="{'selected': selectedImage}" is-flex is-flex-direction-row is-fullwidth >
    <ng-container *ngFor="let image of snippets">
      <div class="gallery-item-wrapper" is-flex is-flex-direction-column is-align-items-left>
        <img *ngIf="image.available === true; else loadingBtn" [src]="image.resourceToken | authSnippet: true | async" class="gallery-item" 
        [ngClass]="{'selected': selectedImage && selectedImage === image, 'not-selected': selectedImage && selectedImage !== image}" (click)="toggleSelect(image)" [class.reloading]="image.available" >
        <p class="image-date has-text-centered" [ngClass]="{'not-selected': selectedImage && selectedImage !== image}">{{ image.timestamp | date:'dd MMM yyyy' }}</p>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #loadingPlaceholder>

  <div class="gallery-item-wrapper" is-flex is-flex-direction-column is-align-items-left>
    <div class="loading-animation" is-flex is-justify-content-center is-align-items-center>
      <i-feather name="loader" class="icon is-spinning"></i-feather>
    </div>
  </div>
</ng-template>

<ng-template #loadingBtn>
  <div class="gallery-item" [ngClass]="{'not-selected': selectedImage}">
    <div class="not-selected"  style="display: flex; align-items: center; justify-content: center; height: 100%;">
      <div class="loader"></div>
    </div>
  </div>
  
  <!-- <button class="button is-primary" disabled>
    &nbsp;
    <strong i18n>Apply filters</strong>
  </button> -->
</ng-template>

