import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { CommonModule } from "@angular/common";
import { PhotosGalleryComponent } from "../photos-gallery/photos-gallery.component";
import { SnippetsGalleryComponent } from "../snippets-gallery/snippets-gallery.component";

type GalleryTab = 'satellite' | 'photos';

@Component({
    standalone: true,
    selector: 'app-combined-gallery',
    templateUrl: './combined-gallery.component.html',
    imports: [
      CommonModule,
      PhotosGalleryComponent,
      SnippetsGalleryComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    styleUrls: ['./combined-gallery.component.scss']
  })

export class CombinedGalleryComponent implements OnInit {

  @Input() userIsCustomer: boolean = false;
  activeTab: GalleryTab = 'photos';

  constructor(
  ) {
  }

  ngOnInit() {
  }

  selectTab(tab: GalleryTab) {
    this.activeTab = tab;
    // if (tab !== 'satellite') {
    //   this.activePhoto = this.photoData[0];
    // }
    // this.emitSlideChange();
  }

}