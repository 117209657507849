import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  delay,
  firstValueFrom,
  map,
  Observable,
  Subscription,
  switchMap
} from 'rxjs';
import {
  NCode,
  PlotEvent,
  Plot,
  PlotPhotoResponse,
  PlotShort,
  PlotShortLike, PlotLike, SfbAssignment,
  PlotMonitors, NdviData, NdviDataMultiple, CoherenceData, Result,
  SnippetsResponse
} from '../model/plot';
import {Polygon} from 'geojson';
import {UtilService} from './util.service';
import {
  AssessmentScapiCreateInput,
  Assessment,
  AssessmentScapiUpdateInput,
  Monitor,
  EventMowingUpdateInput
} from '../model/assessment';
import {amsUrl, amsPhotoUrl, amsSnippetsUrl} from '../events';
import {UserService} from './user.service';
import {GraphqlService} from './graphql.service';
import {Assignment} from '../model';

/**
 * Http options that contain headers needed in every request
 */
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class PlotsService {

  constructor(
    private http: HttpClient,
    private graphql: GraphqlService,
    private util: UtilService,
    private user: UserService) {
  }

  // getPlots(filter: string): Observable<PlotShort[]> {
  //   return this.graphql.queryField({
  //     field: 'features', attributes: [
  //       'id',
  //       'nCodeDeclared {code, codeLabel}',
  //       'area',
  //       'eftasZone'
  //     ], filter
  //   }).pipe(
  //     map((response: any) => response.data.features
  //       .filter((plotShortLike: any) => plotShortLike && plotShortLike.id)
  //       .map((plotShortLike: PlotShortLike) => new PlotShort(plotShortLike)))
  //   );
  // }

  /**
   * Retrieves a single plot with a set of sCAPI specific attributes.
   *
   * @param plotId - The id of the plot to retrieve
   * @return An observable that emits the requested plot
   */
  getScapiPlot(plotId: number): Observable<PlotShort> {
    let filter = `{id: ${plotId}}`;
    return this.graphql.queryField({
      field: 'features', attributes: [
        'id',
        'nCodeDeclared {code, codeLabel}',
        'area',
        'eftasZone',
        'assignmentScapi {username}',
        'assessmentScapi {finished}'
      ], filter
    }).pipe(
      map((response: any) => new PlotShort(response[0]))
    );
  }

  /**
   * Retrieves a number of plots with a set of sCAPI specific attributes, assigned to current user.
   *
   * @param count The number of plots to retrieve at maximum
   * @param offset The start index used for paging
   * @param extraFilters Filters used in addition to the default ones
   * @param watchlist Whether to include only plots that are on current user's watchlist
   * @return An observable that emits the requested plots
   */
  getScapiPlots(count: number, offset: number = 0, extraFilters: string | null, watchlist: boolean = false): Observable<PlotShort[]> {
    const username = this.user.getUsername();
    if (username) {
      let filter = `{assignedScapiUsername: ["${username}"], count: ${count}, offset: ${offset}}`;
      if (watchlist) {
        filter = `{assignedWatchlistUsername: ["${username}"], count: ${count}, offset: ${offset}}`;
      }
      if (extraFilters) {
        const index = filter.lastIndexOf('}');
        filter = this.util.insertString(filter, `, ${extraFilters}`, index);
      }
      return this.graphql.queryField({
        field: 'features', attributes: [
          'id',
          'nCodeDeclared {code, codeLabel}',
          'area',
          'eftasZone',
          'assignmentScapi {username}',
          'assessmentScapi {finished}'
        ], filter
      }).pipe(
        map((response: any) => response
          .filter((plotShortLike: any) => plotShortLike && plotShortLike.id)
          .map((plotShortLike: PlotShortLike) => new PlotShort(plotShortLike)))
      );
    } else {
      throw new Error('No username specified and no username found in storage.');
    }
  }

  /**
   * Retrieves the total amount of plots, as well the amount of plots open, edited and closed, assigned to current user.
   *
   * @param extraFilters (deprecated/not used) Filters used in addition to the default ones
   * @param watchlist Whether to include only plots that are on current user's watchlist
   * @return An observable that emits the requested counts
   */
  getScapiPlotsCount(extraFilters: string | null, watchlist: boolean): Observable<{
    total: number;
    open: number;
    edited: number;
    closed: number
  }> {
    const username = this.user.getUsername();
    if (username) {
      let filter = `{assignedScapiUsername: ["${username}"]}`;
      if (watchlist) {
        filter = `{assignedWatchlistUsername: ["${username}"]}`;
      }
      return this.graphql.queryMultipleFields([{
        alias: 'total', field: 'featuresCount', filter
      }, {
        alias: 'open',
        field: 'featuresCount',
        filter: this.util.insertString(filter, ', assessmentScapi: {exists: false}', filter.length - 1)
      }, {
        alias: 'edited',
        field: 'featuresCount',
        filter: this.util.insertString(filter, ', assessmentScapi: {finished: false, index: -1}', filter.length - 1)
      }, {
        alias: 'closed',
        field: 'featuresCount',
        filter: this.util.insertString(filter, ', assessmentScapi: {finished: true, index: -1}', filter.length - 1)
      }]);
    } else {
      throw new Error('No username specified and no username found in storage.');
    }
  }

  /**
   * Returns a number of specified attributes for a given plot
   *
   * @param plotId - The id of the plot to retrieve
   * @param attributes - Attributes to retrieve
   * @return An Observable that emits an object containing the requested attributes
   */
  getPlotDetailsByName(plotId: number, ...attributes: string[]): Observable<any> {
    // TODO Error handling!
    return this.graphql.queryField({field: 'features', attributes, filter: `{count: 1, id: ${plotId}}`}).pipe(
      map((response: any) => {
        if (response && response.length > 0) {
          return response[0]
        } else {
          console.error(`No plot details for id ${plotId} found. ${attributes.join(', ')}`);
          return null;
        }
      })
    );
  }

    /**
   * Returns a number of specified attributes for a given plot
   *
   * @param plotIds - The id of the plot to retrieve
   * @param attributes - Attributes to retrieve
   * @return An Observable that emits an object containing the requested attributes
   */
    getPlotDetailsByNameMultiple(plotIds: number[], ...attributes: string[]): Observable<any> {
      return this.graphql.queryField({field: 'features', attributes, filter: `{count: ${plotIds.length}, id: [${plotIds.join(',')}]}`}).pipe(
        map((response: any) => {
          return response;
        })
      );
    }
  

  /**
   * Returns a plot's geometry.
   *
   * @param plotId - The id of the plot
   * @return An observable that emits the geometry as polygon
   */
  getPlotGeom(plotId: number): Observable<Polygon> {
    return this.getPlotDetailsByName(plotId, 'geom').pipe(
      map((response: any) => response ? response.geom : null)
    );
  }

  /**
   * Returns a plot's sCAPI assessments.
   *
   * @param plotId - The id of the plot
   * @return An observable that emits the sCAPI assessments
   */
  getScapiAssessments(plotId: number): Observable<Assessment[]> {
    return this.getPlotDetailsByName(plotId, 'assessmentScapi{assessmentDate,comment,eftasId,featureId,finished,marker,nCodeDeclared,nCodeMeasured,operator,revision,tags}').pipe(
      map((response: any) => response ? response.assessmentScapi : null)
    );
  }

  /**
   * Returns a predefined set of attributes for a given plot.
   *
   * @param plotId - The id of the plot to retrieve
   * @return An Observable that emits a Plot
   */
  getPlotDetails(plotId: number): Observable<Plot | null> {
    return this.getPlotDetailsByName(plotId,
      'id',
      'eftasZone',
      'eftasId',
      'nCodeDeclared {code, codeLabel}',
      'area',
      'nCodeDeclared {classCode}',
      'assessmentSfb {operator, comment, nCodeMeasured, tags}',
      'finalResults {monitor, resultCode, nCodeMeasured, photoProof, date, updatedAt}',
      'results {monitor, method, result, resultCode, nCodeMeasured, date, updatedAt, resultComment}'
    ).pipe(
      map((response: PlotLike) => response ? new Plot(response) : null)
    )
  }

  /**
   * Returns a plot's monitors.
   *
   * @param plotId - The id of the plot
   * @return An observable that emits the plot's monitors
   */
  getPlotMonitors(plotId: number): Observable<PlotMonitors> {
    return this.getPlotDetailsByName(plotId,
      'id',
      'finalResults { monitor, resultCode }'
    ).pipe(
      map((response: PlotMonitors) => response)
    )
  }

  getResults(plotId: number): Observable<Result[]> {
    return this.getPlotDetailsByName(plotId, 'id', 'results { monitor, resultCode, date, method }'
    ).pipe(
      map((response: any) => response ? response.results : null)
    )
  }

  /**
   * Returns a plot's events.
   *
   * @param plotId - The id of the plot
   * @return An observable that emits the plot's events
   */
  getPlotEvents(plotId: number): Observable<PlotEvent[]> {
    return this.getPlotDetailsByName(plotId, 'eventMowing {date,source,resultComment,featureId,valid}', 'eventGrazing {date,source,resultComment,featureId,valid}', 'eventPloughing {date,source,resultComment,featureId,valid}').pipe(
      map((response: any) => {
        const events: PlotEvent[] = [];
        const eventMowing: PlotEvent[] = response?.eventMowing || [];
        const eventGrazing: PlotEvent[] = response?.eventGrazing || [];
        const eventPloughing: PlotEvent[] = response?.eventPloughing || [];

        eventMowing.forEach((event: any) => {
          if (event) {
            events.push({
              ...event,
              date: new Date(event.date),
              type: 'mowing'
            });
          }
        });

        eventGrazing.forEach((event: any) => {
          if (event) {
            events.push({
              ...event,
              date: new Date(event.date),
              type: 'grazing'
            });
          }
        });

        eventPloughing.forEach((event: any) => {
          if (event) {
            events.push({
              ...event,
              date: new Date(event.date),
              type: 'ploughing'
            });
          }
        });

        return events;
      })
    );
  }

  /**
   * Updates an event by use of an EventMowingUpdateInput object
   *
   * @param input An EventMowingUpdateInput object
   * @todo evaluate if only used for mowing events
   */
  updateEvent(input: EventMowingUpdateInput): Observable<any> {
    return this.graphql.mutation({field: 'eventMowingUpdate', input: input, returns: true}).pipe(
      map((response: any) => response)
    );
  
    // const query = `mutation {
    //       eventMowingUpdate(input: ${this.util.stringifyGraphQLEnums(input)}) {
    //         ${Object.keys(input).join(', ')}
    //       }
    //     }`;

    // return amsUrl.pipe(
    //   switchMap((url: string) => this.http.post(url + '/graphql', JSON.stringify({query}), httpOptions))
    // );
  }

  /**
   * Returns a plot's coherence data.
   *
   * @param plotId - The id of the plot
   * @return An observable that emits the plot's coherence data
   */
  getCoherenceData(plotId: number): Observable<CoherenceData[]> {
    return this.getPlotDetailsByName(plotId, `measurementsCoherence {median, date, polarisation, orbit}`).pipe(
      map((response: any) => response ? response['measurementsCoherence'] : null)
    );
  }

  /**
   * Returns a plot's ndvi data.
   *
   * @param plotId - The id of the plot
   * @return An observable that emits the plot's ndvi data
   */
  getNdviData(plotId: number): Observable<NdviData[]> {
    return this.getPlotDetailsByName(plotId, `measurementsNdvi {median, date, source}`).pipe(
      map((response: any) => response ? response['measurementsNdvi'] : null)
    );
  }

  /**
   * Returns a plot's ndvi data.
   *
   * @param plotIds - The id of the plot
   * @return An observable that emits the plot's ndvi data
   */
    getNdviDataMultiple(plotIds: number[]): Observable<NdviDataMultiple[]> {
      return this.getPlotDetailsByNameMultiple(plotIds, `id, nCodeDeclared {code}, measurementsNdvi {median, date, source}`).pipe(
        map((response: any) => response)
      );
    }

  // getPlotAssignmentMetaData(): Observable<any> {
  //   return this.graphql.queryField({
  //       field: 'users',
  //       attributes: ['username'],
  //       filter: '{groups: [sfb]}',
  //       alias: 'operatorsSfb'
  //     },
  //     {field: 'users', attributes: ['username'], filter: '{groups: [scapi]}', alias: 'operatorsScapi'},
  //     {field: 'assessmentTag', attributes: ['name', 'description', 'label']},
  //     {field: 'ncode', attributes: ['code', 'codeLabel']},
  //     {field: 'monitor', attributes: ['internalName', 'name']},
  //     {field: 'eftasZone', attributes: ['name']})
  // }

  /**
   * Retrieves a list of valid assessment tags.
   *
   * @return An observable that emits a list of assessment tags
   */
  getAssessmentTags(): Observable<any> {
    return this.graphql.queryField(
      {field: 'assessmentTag', attributes: ['name', 'description', 'label']}).pipe(
      map((response: any) => response));
  }

  /**
   * Retrieves a list of valid NCodes.
   *
   * @return An observable that emits a list of Ncodes
   */
  getNCodes(): Observable<NCode[]> {
    return this.graphql.queryField({field: 'ncode', attributes: ['code', 'codeLabel']}).pipe(
      map((response: any) => response)
    );
  }

  /**
   * Retrieves a list of plot ids that are on a user's the watchlist.
   *
   * @param username - The username for which watchlist to filter
   * @return An observable that emits a list of plot ids
   */
  getPlotIdsOnWatchList(username?: string): Observable<number[]> {
    if (!username) {
      const name = this.user.getUsername();
      if (name) {
        username = name;
      }
    }
    if (username) {
      return this.graphql.queryField({
        field: 'features',
        attributes: ['id'],
        filter: `{assignedWatchlistUsername: ["${username}"]}`
      }).pipe(
        map((response: any) => response.map((feature: { id: number }) => feature.id))
      );
    } else {
      throw new Error('No username specified and no username found in storage.');
    }
  }

  /**
   * Creates a new sCAPI assessment from an AssessmentScapiCreateInput object.
   * @param input - The AssessmentScapiCreateInput used to create the request
   */
  createScapiAssessment(input: AssessmentScapiCreateInput): Observable<any> {
    return this.graphql.mutation({field: 'assessmentScapiCreate', input: input, returns: true}).pipe(
      map((response: any) => response)
    );
  
  //   const query = `mutation {
  //     assessmentScapiCreate(input: ${this.util.stringifyGraphQL(input)}) {
  //       ${Object.keys(input).join(', ')}
  //     }
  //   }`;

  //   return amsUrl.pipe(
  //     switchMap((url: string) => this.http.post(url + '/graphql', JSON.stringify({query}), httpOptions))
  //   );
  // }
  }

  /**
   * Updates an existing sCAPI assessment from an AssessmentScapiUpdateInput object.
   * @param input - The AssessmentScapiUpdateInput used to create the request
   */
  updateScapiAssessment(input: AssessmentScapiUpdateInput): Observable<any> {
    return this.graphql.mutation({field: 'assessmentScapiUpdate', input: input, returns: true}).pipe(
      map((response: any) => response)
    );

    // const query = `mutation {
    //   assessmentScapiUpdate(input: ${this.util.stringifyGraphQL(input)}) {
    //     ${Object.keys(input).join(', ')}
    //   }
    // }`;

    // return amsUrl.pipe(
    //   switchMap((url: string) => this.http.post(url + '/graphql', JSON.stringify({query}), httpOptions))
    // );
  }

  /**
   * Adds a plot to a user's watchlist.
   *
   * @param featureId - The id of the plot to add to the watchlist
   * @param username - (optional) A username to which watchlist the plot should be added. Defaults to current user.
   */
  addPlotToWatchlist(featureId: number, username?: string) {
    if (!username) {
      const name = this.user.getUsername();
      if (name) {
        username = name;
      }
    }
    if (username) {
      const input = {
          assignments: [{featureId: featureId}],
            username: username
        };
      return this.graphql.mutation({field: 'assignmentWatchlistAddByUser', input: input, returns: false}).pipe(
        map((response: any) => response)
      );

      // const query = `mutation {
      //   assignmentWatchlistAddByUser(input: {
      //     assignments: [{featureId: ${featureId}}],
      //       username: "${username}"
      //   })
      // }`;

      // return amsUrl.pipe(
      //   switchMap((url: string) => this.http.post(url + '/graphql', JSON.stringify({query}), httpOptions))
      // );
    } else {
      throw new Error('No username specified and no username found in storage.');
    }
  }

  /**
   * Removes a plot from a user's watchlist.
   *
   * @param featureId - The id of the plot to remove from the watchlist
   * @param username - (optional) A username from which watchlist the plot should be removed. Defaults to current user.
   */
  removePlotFromWatchlist(featureId: number, username?: string) {
    if (!username) {
      const name = this.user.getUsername();
      if (name) {
        username = name;
      }
    }
    if (username) {
      const input = {
          assignments: [{featureId: featureId}],
            username: username
        };
      return this.graphql.mutation({field: 'assignmentWatchlistRemoveByUser', input: input, returns: false}).pipe(
        map((response: any) => response)
      );

      // const query = `mutation {
      //   assignmentWatchlistRemoveByUser(input: {
      //     assignments: [{featureId: ${featureId}}],
      //       username: "${username}"
      //   })
      // }`;

      // return amsUrl.pipe(
      //   switchMap((url: string) => this.http.post(url + '/graphql', JSON.stringify({query}), httpOptions))
      // );
    } else {
      throw new Error('No username specified and no username found in storage.');
    }
  }

  /**
   * Assigns a number of plots to an operator's task list or watch list
   *
   * @param assignment - The Assignment data
   */
  assignOperator(assignment: Assignment): Observable<any> {
    const capitalizeFirstLetter = (s: string): string => {
      return s.charAt(0).toUpperCase() + s.slice(1);
    }

    let method = 'Sfb';
    if (assignment.method === 'scapi' && assignment.target === 'task-list') {
      method = 'Scapi';
    } else if (assignment.method === 'scapi' && assignment.target === 'watch-list') {
      method = 'Watchlist';
    }

    const assignments = assignment.plotIds.map(plotId => ({featureId: plotId}));

    const input = {
        assignments: assignments,
        username: assignment.operator
    };
    return this.graphql.mutation({field: 'assignment' + capitalizeFirstLetter(method) + 'AddByUser',
      input: input, returns: false}).pipe(
      map((response: any) => response)
    );

    // const query = `mutation {
    //   ${'assignment' + capitalizeFirstLetter(method) + 'AddByUser'}(input: {
    //     assignments: ${this.util.stringifyGraphQL(assignments)},
    //     username: "${assignment.operator}"
    //   })
    // }`;

    // return amsUrl.pipe(
    //   switchMap((url: string) => this.http.post(url + '/graphql', JSON.stringify({query}), httpOptions))
    // );
  }

  /**
   * Removes any sCAPI assignments from a number of plots.
   *
   * @param plots - The plots to remove the assignments from
   */
  async unassignOperator(plots: PlotShort[]): Promise<{ responses: any[]; errors: any[] }> {
    const responses = [];
    const errors = [];
    const plotIdsByUser: { user: string; plotIds: number[] }[] = [];
    plots.forEach(plot => {
      let entry = plotIdsByUser.find(e => e.user === plot.scapiUser);
      if (!entry) {
        entry = {user: plot.scapiUser as string, plotIds: []};
        plotIdsByUser.push(entry);
      }
      entry.plotIds.push(plot.id);
    });
    for (let entry of plotIdsByUser) {
      const assignments = entry.plotIds.map(plotId => ({featureId: plotId}));

      const input = {
          assignments: assignments,
          username: entry.user
        };

      // const query = `mutation {
      //   assignmentScapiRemoveByUser(input: {
      //     assignments: ${this.util.stringifyGraphQL(assignments)},
      //     username: "${entry.user}"
      //   })
      // }`;

      try {
        const response = await firstValueFrom(this.graphql.mutation({field: 'assignmentScapiRemoveByUser',
          input: input, returns: false}).pipe(
          map((response: any) => response)
        ));
        responses.push(response);
      } catch (e) {
        errors.push(e);
      }
    }
    return {responses, errors};
  }

  getSnippetsData(plotId: number, productType: string, allSnippets:boolean): Observable<SnippetsResponse> {
    return amsSnippetsUrl.pipe(
      switchMap((url: string) => this.http.get(url + '/snippets?featureId=' + plotId.toString() 
      + '&productType=' + productType + '&complete=' + !allSnippets)),
    ) as Observable<SnippetsResponse>;
  }



  /**
   * Retrieves the photo server data for a given state and plot.
   *
   * @param state - The state id
   * @param plotId - ID of the plot to retrieve the data for
   * @return An observable that emits a PlotPhotoResponse object
   */
  getPhotoServerData(state: string | undefined, plotId: number): Observable<PlotPhotoResponse> {
    return amsPhotoUrl.pipe(
      switchMap((url: string) => this.http.get(url + '/metadata?bundesland=' + state + '&schlag=' + plotId.toString() + '&size=999')),
    ) as Observable<PlotPhotoResponse>;
  }

  /**
   * Sends photo metadata to the photo server.
   *
   * @param data - The metadata to send
   * @todo add interface and documentation for metadata
   */
  postPhotoMetadata(data: any): Observable<any> {
    return amsPhotoUrl.pipe(
      switchMap((url: string) => this.http.put(url + '/metadata', JSON.stringify(data), httpOptions))
    );
  }

  /**
   * Returns a predefined set of attributes for a given monitor.
   *
   * @param name - The monitor's name
   * @return An observable that emits a Monitor object
   */
  getMonitor(name: string): Observable<Monitor> {
    return this.graphql.queryField({
      field: 'monitor', attributes: [
        'name',
        'dateStart',
        'dateEnd',
        'description',
        'officialPeriod'
      ]
    }).pipe(
      map((response: any) => response.find((x: Monitor) => x.name === name)));
  }

  /**
   * Called when uploading sFB assignment data. Sends to queries to the server. The first one releases the specified
   * plots into sFB. The second one commits the assignments.
   *
   * @param sfbAssignments - sFB assignments to be sent
   */
  async sendSfbAssignments(sfbAssignments: SfbAssignment[]): Promise<{ errors: any[]; responses: any[] }> {
    const combinedResponse: { errors: any[]; responses: any[] } = {errors: [], responses: []};

    // make sure plots are available for sfb assignment first
    let plotIds: { featureId: number }[] = [];
    sfbAssignments.forEach(assignmentsPerUser => {
      plotIds = plotIds.concat(assignmentsPerUser.assignments.map(assignment => ({featureId: assignment.featureId})));
    });
    try {
      const response = await firstValueFrom(this.releasePlotsIntoSfb(plotIds));
      if (response.errors) {
        combinedResponse.errors = combinedResponse.errors.concat(response.errors);
      }
    } catch (e) {
      combinedResponse.errors.push({message: e});
    }

    // Waiting for server to finish releasing plots into sfb
    if (combinedResponse.errors.length === 0) {
      try {
        await this.waitForRelease();
      } catch (e) {
        combinedResponse.errors.push(e);
      }
    }

    if (combinedResponse.errors.length === 0) {
      const amsUrl = await this.getAMSUrl();

      // do the actual assignment
      for (let assignmentsPerUser of sfbAssignments) {
        const input = {
            username: assignmentsPerUser.username,
            assignments: assignmentsPerUser.assignments
          };

        // const query = `mutation {
        //   assignmentSfbAddByUser(input: {
        //     username: "${assignmentsPerUser.username}",
        //     assignments: ${this.util.stringifyGraphQL(assignmentsPerUser.assignments)}
        //   })
        // }`;

        try {
          // const response = await firstValueFrom(this.http.post(amsUrl + '/graphql', JSON.stringify({query}), httpOptions));
          const response = await firstValueFrom(this.graphql.mutation({field: 'assignmentSfbAddByUser',
            input: input, returns: false}).pipe(
            map((response: any) => response)
          ));
          
          combinedResponse.responses.push(response);
        } catch (e) {
          combinedResponse.errors.push({message: e});
        }
      }
    }

    return combinedResponse;
  }

  /**
   * Sends a query to the ams server to release a number of plots into sFB. The release may not be carried out
   * immediately by the server. The waitForRelease() function can be used to check the current state.
   *
   * @param plotIds - IDs of the plots to be released into sFB
   * @private
   */
  private releasePlotsIntoSfb(plotIds: { featureId: number }[]): Observable<any> {
    const input = {
      features: plotIds
    }
    return this.graphql.mutation({field: 'releaseIntoSfb', input: input, returns: false}).pipe(
      map((response: any) => response)
    );
    // const query = `mutation {
    //         releaseIntoSfb(input: {
    //           features: ${this.util.stringifyGraphQL(plotIds)}
    //         })
    //     }`
    // console.log(query);
    // return amsUrl.pipe(
    //   switchMap((url: string) => this.http.post(url + '/graphql', JSON.stringify({query}), httpOptions))
    // );
  }

  /**
   * Checks periodically if there is any ongoing release pending. Resolves when the release has been carried out.
   *
   * @private
   */
  private async waitForRelease() {
    const query = "query { isReleaseRunning }";
    let response: any;
    do {
      response = await firstValueFrom(amsUrl.pipe(
        delay(1000),
        switchMap((url: string) => this.http.post(url + '/graphql', JSON.stringify({ query }), httpOptions))
      ));
    } while (!(response && response.data && response.data.isReleaseRunning === false));
  }

  /**
   * Wrapper for the amsUrl Subject to be used as a promise
   *
   * @private
   * @todo evaluate if necessary (firstValueOf(), lastValueOf() might be used instead)
   */
  private getAMSUrl(): Promise<string> {
    let subscription: Subscription;
    return new Promise((resolve, reject) => {
      subscription = amsUrl.subscribe({
        next: url => resolve(url),
        error: err => reject(err),
        complete: () => subscription.unsubscribe()
      });
    });
  }
}
