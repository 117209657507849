export type PlotAttribute = 'id' |'eftasZone' |'eftasId' |'nCodeDeclared' |'area' |'assessmentSfb' |'finalResults' |'results';

export type PlotShortAttribute = 'id' | 'eftasId' | 'nCodeDeclared' | 'nCodeMeasured' | 'classCode' | 'area' |
  'eftasZone' | 'scapiUser' | 'watchlistUsers' | 'tags' | 'state' | 'scapiComments' | 'ALL_NBF' | 'ALL_NUTZART' | 'BP_MINDEST_BRA_STR' |
  'BP_MINDEST_DGL' | 'SPERR_BRA' | 'latestScapiAssessment';
export type PlotNumberAttribute = 'area' | 'areaPerimeter' | 'id';

export type StatisticsShortAttribute = 'ncode.code' | 'ncode.classCode' | 'scapiDesignatedCount' | 'scapiNotEditedCount' | 'scapiEditingNotFinishedCount' | 'scapiEditingFinishedCount' | 'sfbDesignatedAndAssignedCount';

export type ControlMethod = 'SENTINEL' | 'PLANET' | 'CROP_ANALYZER' | 'SCAPI' | 'SFB';

export const PlotFieldLabels = {
  area: 'Area',
  areaPerimeter: 'Perimeter',
  eftasId: 'EftasID',
  eftasZone: 'Zone',
  finalResults: 'Final Results',
  geom: 'Geometry',
  id: 'ID',
  nCodeDeclared: 'NCode Declared',
  results: 'Results',
  unusable: 'Unusable'
}

export const PlotShortFieldLabels = {
  id: 'ID',
  eftasId: 'EFTAS-ID',
  nCodeDeclared: 'NCode-D',
  nCodeMeasured: 'NCode-M',
  classCode: 'Nutzart-Klasse',
  area: 'Größe',
  eftasZone: 'Zone',
  scapiUser: 'sCAPI-Bearbeiter*in',
  watchlistUsers: 'Auf Watchliste von',
  tags: 'Tags',
  state: 'Status',
  scapiComments: 'Kommentare',
  ALL_NUTZART: 'NUTZ',
  BP_MINDEST_DGL: 'LWT_DGL',
  BP_MINDEST_BRA_STR: 'MIND_BRA',
  ALL_NBF: 'NBF',
  SPERR_BRA: 'SPERR_BRA',
  KENN: 'KENN',
  latestScapiAssessment: 'Zuletzt bearbeitet'
}

export const MonitorShortLabels = {
  NUTZ: 'NUTZ', // not nrw
  ALL_NUTZART: 'NUTZ', // nrw
  LWT_DGL: 'LWT_DGL', // not nrw
  BP_MINDEST_DGL: 'LWT_DGL', // nrw
  MIND_BRA: 'MIND_BRA', // not nrw
  BP_MINDEST_BRA_STR: 'MIND_BRA', // nrw
  NBF: 'NBF', // not nrw
  ALL_NBF: 'NBF', // nrw
  SPERR_BRA: 'SPERR_BRA', // all
  KENN: 'KENN' // all (used only in NI)
}

export const PlotShortAliases: {field: PlotShortAttribute; aliases: any}[] = [{
  field: 'state',
  aliases: {'open': 'n.B.', 'edited': 'i.B.', 'closed': 'fin', 'unknown': '?'}
}];

export const PlotAttributeTypes = {
  area: 'number',
  areaPerimeter: 'number',
  eftasId: 'string',
  eftasZone: 'string',
  finalResults: 'object',
  geom: 'object',
  id: 'number',
  nCodeDeclared: 'object',
  results: 'object',
  unusable: 'boolean'
};

export const PlotShortAttributeTypes = {
  id: 'number',
  eftasId: 'string',
  nCodeDeclared: 'number',
  nCodeMeasured: 'number',
  area: 'number',
  eftasZone: 'string',
  scapiUser: 'string',
  watchlistUsers: 'string',
  classCode: 'number',
  tags: 'string',
  state: 'string',
  scapiComments: 'string',
  ALL_NBF: 'number',
  ALL_NUTZART: 'number',
  BP_MINDEST_BRA_STR: 'number',
  BP_MINDEST_DGL: 'number',
  SPERR_BRA: 'number',
  latestScapiAssessment: 'Date'
};

export const PlotNumberTypes = {
  area: {type: 'float', precision: 2},
  areaPerimeter: {type: 'float', precision: 2},
  id: {type: 'integer', precision: 0}
}

export interface PlotLike {
  id: number;
  eftasZone?: string;
  eftasId?: string;
  nCodeDeclared?: {classCode: number, code: number, codeLabel: string};
  area?: number;
  assessmentSfb: {operator: string, comment?: string, nCodeMeasured?: number, tags: string[]}[];
  finalResults: {monitor?: string, resultCode?: number, nCodeMeasured?: number, photoProof?: number, date?: string, updatedAt?: string}[];
  results: {monitor?: string, method?: 'SENTINEL' | 'PLANET' | 'CROP_ANALYZER' | 'SCAPI' | 'SFB', result?: string, resultCode?: number, nCodeMeasured?: string, date?: string, updatedAt?: string, resultComment?: string}[];
}

export interface PlotShortLike {
  id: number;
  eftasId?: string;
  nCodeDeclared?: {code: number; codeLabel: string; classCode: number},
  area?: number;
  eftasZone?: string;
  assignmentScapi?: {username: string}[];
  assignmentWatchlist?: {username: string}[];
  assessmentScapi?: {assessmentDate: string; nCodeMeasured: number, tags: string[]; comment?: string; finished?: boolean}[];
  finalResults?: {monitor: string, resultCode: number}[];
}
export interface FinalResult {
  date?: string;
  featureId?: number;
  monitor?: string;
  nCodeMeasured?: number;
  photoProof?: number;
  resultCode?: number;
  updatedAt?: string;
}

export interface PlotMonitors {
  id: number,
  finalResults: FinalResult[];
}


export interface NCode {
  classCode?: number;
  code?: number;
  codeLabel?: string;
  mainPeriodEndDoy?: number;
  mainPeriodStartDoy?: number;
  stateCode?: number;
  vegetationEndDoy?: number;
  vegetationStartDoy?: number;
  winterculture?: boolean;
}

export interface Result {
  date?: string;
  deliverId?: number;
  method?: any; // ControlMethod
  monitor?: string;
  nCodeMeasured?: string;
  result?: string;
  resultCode?: number;
  resultComment?: string;
  updatedAt?: string;
}


export interface AssignmentScapi {
  username?: string;
}

export interface ChartData {
  median: number;
  mean?: number;
  min?: number;
  max?: number;
  sd?: number;
  total?: number;
  buffer?: number;
  polarisation?: 'VH' | 'VV';
  date: string;
  source?: 'SENTINEL' | 'PLANET';
}

export interface CoherenceData {
  median: number;
  date: string;
  polarisation: string;
  orbit: string;
}

export interface NdviData {
  median: number;
  date: string;
  source: string;
}

export interface NdviDataMultiple {
  id: number;
  nCodeDeclared: NCode;
  measurementsNdvi: NdviData[];
}


export interface NdviDataSorted {
  date: Date,
  ndviSentinel: number | null,
  coherences?: {orbit: string, polarisation: 'VH' | 'VV', median: number}[],
  ndviPlanet: number | null
  }

  export interface Coherence {
    orbit: string,
    polarisation: 'VH' | 'VV',
    values: {
      date: Date,
      median: number
    }[]
  };


export interface PlotEvent {
  // The amount of change in coherence at this event
  cohChange?: number;

  // Flag whether coherence data was used during the evaluation
  cohUsed?: boolean;

  // The date of the satellite scene, when the event was discovered
  date: Date;

  // The feature associated with this event
  featureId: number;

  // The amount of ndvi change at this event
  ndviChange?: number;

  // Flag whether ndvi was used during the evaluation
  ndviUsed?: boolean;

  // Measurement for similar events nearby
  regionalAnalysis?: number;

  // Comment set during evaluation
  resultComment?: string;

  // The information source (control method)
  source: ControlMethod;

  type?: 'Mowing' | 'Grazing' | 'Ploughing';

  // Set validity of a detected event, if it can be evaluated.
  valid: boolean;
}

export class PlotShort {
  id: number;
  eftasId?: string;
  area?: number;
  eftasZone?: string;
  nCodeDeclared?: number;
  nCodeMeasured?: number;
  classCode?: number;
  codeLabel?: string;
  scapiUser?: string;
  scapiComments?: string[];
  watchlistUsers?: string[];
  tags?: string[];
  ALL_NBF?: number;
  ALL_NUTZART?: number;
  BP_MINDEST_BRA_STR?: number;
  BP_MINDEST_DGL?: number;
  SPERR_BRA?: number;
  state: 'open' | 'edited' | 'closed' | 'unknown';
  finalResults?: {monitor: string, resultCode: number}[];
  latestScapiAssessment?: Date;

  constructor(plotShortLike: PlotShortLike) {
    this.id = plotShortLike.id;
    this.eftasId = plotShortLike.eftasId;
    this.nCodeDeclared = plotShortLike.nCodeDeclared && plotShortLike.nCodeDeclared.code ? plotShortLike.nCodeDeclared.code : undefined;
    this.nCodeMeasured = plotShortLike.assessmentScapi && plotShortLike.assessmentScapi.length >= 1 && plotShortLike.assessmentScapi[plotShortLike.assessmentScapi.length - 1].nCodeMeasured ? plotShortLike.assessmentScapi[plotShortLike.assessmentScapi.length - 1].nCodeMeasured : undefined;
    this.classCode = plotShortLike.nCodeDeclared && plotShortLike.nCodeDeclared.classCode ? plotShortLike.nCodeDeclared.classCode : undefined;
    this.codeLabel = plotShortLike.nCodeDeclared && plotShortLike.nCodeDeclared.codeLabel ? plotShortLike.nCodeDeclared.codeLabel : undefined;
    this.area = plotShortLike.area;
    this.eftasZone = plotShortLike.eftasZone;
    this.scapiUser = plotShortLike.assignmentScapi && plotShortLike.assignmentScapi.length >= 1  && plotShortLike.assignmentScapi[plotShortLike.assignmentScapi.length - 1].username ? plotShortLike.assignmentScapi[plotShortLike.assignmentScapi.length - 1].username : undefined;
    this.watchlistUsers = plotShortLike.assignmentWatchlist && plotShortLike.assignmentWatchlist.length >= 1 ? plotShortLike.assignmentWatchlist.map(assignment => assignment.username) : [];
    this.tags = plotShortLike.assessmentScapi && plotShortLike.assessmentScapi.length >= 1 && plotShortLike.assessmentScapi[plotShortLike.assessmentScapi.length - 1].tags ? plotShortLike.assessmentScapi[plotShortLike.assessmentScapi.length - 1].tags : undefined;
    this.finalResults = plotShortLike.finalResults;
    if (plotShortLike.assessmentScapi) {
      this.state = 'open';
      if (plotShortLike.assessmentScapi.length >= 1) {
        // set state
        if (plotShortLike.assessmentScapi.find(assessment => assessment.finished === false)) {
          this.state = 'edited';
        } else {
          this.state = 'closed';
        }
        // set date
        this.latestScapiAssessment = plotShortLike.assessmentScapi
          .map(assessment => new Date(assessment.assessmentDate))
          .sort((dateA, dateB) => dateB.valueOf() - dateA.valueOf())[0];
      }
      const comments = plotShortLike.assessmentScapi
        .map(assessment => assessment.comment)
        .filter(comment => !!comment);
      if (comments && comments.length >= 1) {
        this.scapiComments = comments as string[];
      }
    } else {
      this.state = 'unknown';
    }
    if (plotShortLike.finalResults) {
      plotShortLike.finalResults.forEach(result => {
        switch (result.monitor) {
          case 'ALL_NBF':
            this.ALL_NBF = result.resultCode;
            break;
          case 'ALL_NUTZART':
            this.ALL_NUTZART = result.resultCode;
            break;
          case 'BP_MINDEST_BRA_STR':
            this.BP_MINDEST_BRA_STR = result.resultCode;
            break;
          case 'BP_MINDEST_DGL':
            this.BP_MINDEST_DGL = result.resultCode;
            break;
          case 'SPERR_BRA':
            this.SPERR_BRA = result.resultCode;
            break;
        }
      });
    }
  }

  formatDateToDDMMYYYY(date: Date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so +1
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  }

  getAsString(attr: PlotShortAttribute): string | undefined {
    let value = this[attr];
    if (PlotNumberTypes.hasOwnProperty(attr)) {
      const entry = PlotNumberTypes[attr as PlotNumberAttribute];
      if (entry.type === 'float') {
        value = Math.round(value as number * (10 ** entry.precision)) / (10 ** entry.precision);
        let precision = this.precision(value);
        if (precision < entry.precision) {
          if (precision === 0) {
            value = `${value}.`;
          }
          while (precision < entry.precision) {
            value += '0';
            precision++;
          }
        }
      }
    } else if (attr === 'latestScapiAssessment') {
      const date = this[attr] as Date | undefined;
      value = date ? this.formatDateToDDMMYYYY(this[attr] as Date) : '';
    } else {
      const finalResultsValue = this.getFinalResultValue(attr, this.finalResults);
      if (finalResultsValue) {
        value = finalResultsValue;
      }
      // if (!value) {
        const entry = PlotShortAliases.find(e => e.field === attr);
        if (entry && typeof value === 'string' && entry.aliases[value]) {
          value = entry.aliases[value];
        }
      // }
    }
    return value?.toString();
  }

  clone(): PlotShort {
    const plotShortLike: PlotShortLike = {
      id: this.id
    };

    const copy = new PlotShort(plotShortLike);
    copy.eftasId = this.eftasId;
    copy.area = this.area;
    copy.eftasZone = this.eftasZone;
    copy.nCodeDeclared = this.nCodeDeclared;
    copy.nCodeMeasured = this.nCodeMeasured;
    copy.classCode = this.classCode;
    copy.codeLabel = this.codeLabel;
    copy.scapiUser = this.scapiUser;
    copy.scapiComments = this.scapiComments;
    copy.watchlistUsers = this.watchlistUsers;
    copy.tags = this.tags;
    copy.ALL_NBF = this.ALL_NBF;
    copy.ALL_NUTZART = this.ALL_NUTZART;
    copy.BP_MINDEST_BRA_STR = this.BP_MINDEST_BRA_STR;
    copy.BP_MINDEST_DGL = this.BP_MINDEST_DGL;
    copy.SPERR_BRA = this.SPERR_BRA;
    copy.state = this.state;
    copy.finalResults = this.finalResults;
    copy.latestScapiAssessment = this.latestScapiAssessment;

    return copy;
  }

  private precision(n: number): number {
    if (!isFinite(n)) return 0;
    let e = 1, p = 0;
    while (Math.round(n * e) / e !== n) { e *= 10; p++; }
    return p;
  }

  private getFinalResultValue(attr: string, finalResults: {monitor: string; resultCode: number}[] | undefined): number | undefined {
    let value: number | undefined;
    if (finalResults) {
      if ((PlotFieldLabels as any)[attr]) {
        attr = (PlotFieldLabels as any)[attr];
      }
      value = finalResults.find(result => result.monitor === attr)?.resultCode;
    }
    return value;
  }
}

export class Plot {
  id: number;
  eftasZone?: string;
  eftasId?: string;
  nCodeDeclared?: {classCode: number, code: number, codeLabel: string};
  area?: number;
  assessmentSfb: {operator: string, comment?: string, nCodeMeasured?: number, tags: string[]}[];
  finalResults: {monitor?: string, resultCode?: number, nCodeMeasured?: number, photoProof?: number, date?: Date, updatedAt?: Date}[];
  results: {monitor?: string, method?: 'SENTINEL' | 'PLANET' | 'CROP_ANALYZER' | 'SCAPI' | 'SFB', result?: string, resultCode?: number, nCodeMeasured?: string, date?: Date, updatedAt?: Date, resultComment?: string}[];

  get keys(): PlotAttribute[] {
    return Object.keys(this) as PlotAttribute[];
  }

  constructor(plot: PlotLike) {
    this.id = plot.id;
    this.eftasZone = plot.eftasZone;
    this.eftasId = plot.eftasId;
    this.nCodeDeclared = plot.nCodeDeclared;
    this.area = plot.area;
    this.assessmentSfb = plot.assessmentSfb;
    this.finalResults = plot.finalResults.map(r => {
      const result: any = {
        monitor: r.monitor, resultCode: r.resultCode, nCodeMeasured: r.nCodeMeasured, photoProof: r.photoProof
      };
      if (r.date) {
        result.date = new Date(r.date);
      }
      if (r.updatedAt) {
        result.updatedAt = new Date(r.updatedAt);
      }
      return result;
    });
    this.results = plot.results.map(r => {
      const result: any = {monitor: r.monitor, method: r.method, result: r.result, resultCode: r.resultCode, resultComment: r.resultComment, nCodeMeasured: r.nCodeMeasured};
      if (r.date) {
        result.date = new Date(r.date);
      }
      if (r.updatedAt) {
        result.updatedAt = new Date(r.updatedAt);
      }
      return result;
    });
  }

  get(attr: PlotAttribute): any {
    return this[attr];
  }

  getLastSfbAssessment() {
    if (this.assessmentSfb.length >= 1) {
      return this.assessmentSfb[this.assessmentSfb.length - 1];
    } else {
      return undefined;
    }
  }

  getSfbTags() {
    if (this.assessmentSfb[this.assessmentSfb.length - 1].tags.length > 0) {
      return this.assessmentSfb[this.assessmentSfb.length - 1].tags;
    } else {
      return null;
    }
  }
}

export interface PhotoClassificationResult {
  error: {
    message: string
  },
  meta: {
    done: string,
    enqueued: string,
    requestInfo: {}
  },
  raw: {
    message: string
  },
  method: string,
  perc: number,
  predictClass: string
}

export interface PlotPhotosData {
  additional: string,
  comment: string,
  detail: boolean,
  imageId: string,
  location: {
    lat: number,
    lon: number,
    srid: number
  },
  operator: string,
  recordDate: string,
  results: [PhotoClassificationResult],
  schlag: string,
  source: string,
  uploadDate: string,
  usable: boolean,
  viewdirection: number
};

export interface PlotPhotoResponse {
  content: [PlotPhotosData],
  empty: boolean,
  first: boolean,
  last: boolean,
  number: number,
  numberOfElements: number,
  pageable: any,
  size: number,
  sort: {
    empty: boolean,
    sorted: boolean,
    unsorted: boolean
  },
  totalElements: number,
  totalPages: number
}

export interface SnippetsData {
  resourceToken: string,
  timestamp: string,
  available: boolean
}


export interface SnippetsResponse {
  featureId: number,
  productType: string,
  resources: [SnippetsData]
}


export interface SfbAssignment {
  assignments: {featureId: number, mapIndex: string}[];
  username: string;
}

export const ClassificationLabels = {
  monocultures: 'Kulturarten',
  artificial_land: 'versiegelte Flächen',
  bare_soil: 'blanker Boden',
  barley: 'Gerste',
  brassica: 'Gemüsekohl',
  buckwheat: 'Buchweizen',
  carrot: 'Möhre',
  celery: 'Sellerie',
  clover: 'Klee',
  field_bean: 'Ackerbohne',
  flowering_area: 'Blühflächen (Brachen)',
  garden_bean: 'Gartenbohne',
  grassland: 'Gras',
  harvested_grain_field: 'Getreide_geerntet',
  hemp: 'Hanf',
  leek: 'Lauch',
  linseed_flax: 'Lein / Flachs',
  lucerne: 'Luzerne',
  lupin: 'Lupinen',
  maize: 'Mais',
  oat: 'Hafer',
  pea: 'Erbsen',
  potato: 'Kartoffeln',
  pumpkin: 'Kürbis',
  rye: 'Roggen',
  shrubland: 'verbuschte Flächen',
  silphium: 'Silphium',
  soybean: 'Sojabohnen',
  spelt: 'Dinkel',
  strawberry: 'Erdbeeren',
  sugar_beet: 'Zuckerrüben',
  sunflower: 'Sonnenblume',
  swiss_chard__beetroot__red_beet: 'Mangold',
  tomatoe: 'Tomaten',
  triticale: 'Triticale',
  vineyard: 'Wein',
  wheat: 'Weizen',
  winter_oilseed_rape: 'Winterraps',
  fallow: 'Brachen',
  fallow_blooming: 'blühende Brachen',
  fallow_faded: 'verblühte Brache',
  fallow_grassy: 'vergraste Brachen',
  fallow_mulched: 'gemulchte Brachen',
  fallow_self_planted: 'Selbstbegrünte Brachen',
  mixed_crops: 'Mischkulturen',
  green_manure_in_main_crop_cultivation: 'Gründüngung im Hauptfruchtanbau',
  maize_beans: 'Mais-Bohnen',
  maize_sunflowers: 'Mais-Sonnenblumen',
  mixture_mostly_grain: 'Gemenge Getreide-Leguminosen (mehr Getreide)',
  mixture_mostly_legumes: 'Gemenge Getreide-Leguminosen (mehr Leguminosen)',
  mixture_wild_plants_for_energy_production: 'Wildpflanzenmischung',
  other_mixed_crops: 'Andere gemischte Kulturen',
  summer_grains: 'Sommermengegetreide',
  winter_grains: 'Wintermengegetreide',
  grass: 'lwt. Tätigkeit',
  mown: 'Mahd',
  unmown: 'keine Mahd',
  grazed: 'Beweidung',
  CROPANALYZER_MONOCULTURES: 'Kulturarten',
  CROPANALYZER_FALLOW: 'Brachen',
  CROPANALYZER_MIXEDCROPS: 'Mischkulturen',
  CROPANALYZER_GRASS: 'lwt. Tätigkeit',
  CROPANALYZER_24: 'Kulturarten (alt)',
  FLORAINCOGNITA: 'Flora Incognita'
}

