import {Pipe, PipeTransform} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, switchMap, firstValueFrom} from 'rxjs';
import {amsPhotoUrl} from "../events";

@Pipe({
  standalone: true,
  name: 'authImage'
})
export class AuthImagePipe implements PipeTransform {

  constructor(
    private http: HttpClient
  ) {
  }

  getPhotoServerData(id: string, small: boolean): Observable<any> {
    const size = small ? '&size=900x510' : '&size=1600x1200';
    return amsPhotoUrl.pipe(
      switchMap((url: string) => this.http.get(url + '/image?imageId=' + id + size, {responseType: 'blob'}))
    ) as Observable<any>;
  }

  async rotateImage(str: string, rotation: number = 0): Promise<string> {
    rotation %= 360;
    return new Promise((resolve, reject) => {
      const sideways = rotation === 90 || rotation === 270;
      const canvas = document.createElement('canvas');
      const img = new Image();
      img.setAttribute("crossorigin", "anonymous");
      img.onload = function () {
        const width = sideways ? img.height : img.width;
        const height = sideways ? img.width : img.height;

        const context = canvas.getContext('2d');
        if (!context) {
          reject("no context");
        }
        else {
          canvas.width = width;
          canvas.height = height;
          context.translate(width / 2, height / 2);
          context.rotate((Math.PI / 180) * rotation);
          context.drawImage(img, -img.width / 2, -img.height / 2);
          console.log('loaded an image');
          resolve(canvas.toDataURL());
        }
      };
      img.onerror = (err) => reject(err);
      img.src = str;
    });
  }

  /**
   * Transforms an image ID into a data URL string.
   * Fetches image data as a Blob from the server and converts it to a base64-encoded data URL.
   * If the fetch operation fails, returns a fallback image URL.
   *
   * @param id - The unique identifier for the image to be fetched.
   * @param small - A boolean indicating whether to fetch a smaller version of the image.
   * @returns A Promise that resolves to a base64-encoded data URL of the image or a fallback image URL.
   */

  async transform(id: string, small: boolean, rotation: number = 0): Promise<string> {
    try {
      const imageBlob = await firstValueFrom(this.getPhotoServerData(id, small));
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        if (rotation % 360 !== 0) {
          reader.onloadend = async () => resolve(await this.rotateImage(reader.result as string, rotation));
        } else {
          reader.onloadend = () => resolve(reader.result as string);
        }
        reader.readAsDataURL(imageBlob);
      });
    } catch {
      return 'assets/img/fallback.png';
    }
  }

}
