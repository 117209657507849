<div class="tabs dashboard-component-tabs is-boxed is-small is-marginless">
    <ul>
      <!-- maybe later -->
      <!-- <li [ngClass]="{'is-active': activeTab === 'satellite'}">
        <a (click)="selectTab('satellite')">
          <span>Satellite</span>
        </a>
      </li> -->
      <li [ngClass]="{'is-active': activeTab === 'photos'}">
          <a (click)="selectTab('photos')"><span i18n>Photos</span></a> 
      </li>
    </ul>
</div>
  
<div class="gallery">
  <!-- <div *ngIf="activeTab === 'satellite'">
      <app-snippets-gallery [userIsCustomer]="userIsCustomer"></app-snippets-gallery>
  </div> -->
  <div *ngIf="activeTab === 'photos'" >
      <app-photos-gallery [userIsCustomer]="userIsCustomer"></app-photos-gallery>
  </div>
</div>