import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from "@angular/common";
import { plotImageSelected, selectedPlotsChange, showNotification } from '../../events';
import { StatesService } from "../../services/states.service";
import { PlotsService } from "../../services/plots.service";
import { SnippetsResponse, SnippetsData } from '../../model/plot';
import { AuthSnippetPipe } from '../../pipes/auth-snippet.pipe';
import {FeatherModule} from "angular-feather";


@Component({
  standalone: true,
  selector: 'app-snippets-gallery',
  templateUrl: './snippets-gallery.component.html',
  imports: [
    CommonModule,
    AuthSnippetPipe,
    FeatherModule,
    FormsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  styleUrls: ['./snippets-gallery.component.scss']
})
export class SnippetsGalleryComponent implements OnInit {
  @Input() userIsCustomer: boolean = false;

  snippets: SnippetsData[] = [];
  selectedImage: any;
  allSnippets = false;
  productType = "sentinel2-rgb";
  hideCloudImages: boolean = false;
  selectedPlotId: number | null = null;
  reloadingSnippets: boolean = false;

  constructor(
    private plotsService: PlotsService
  ) {
  }

  /**
   *
   * @return {Promise<void>} A promise that resolves when the initialization is complete.
   */
  async ngOnInit() {
    console.log('loaded snippets gallery');
      selectedPlotsChange
      .subscribe(async data => {
        console.log(data);
        this.resetPhotoData();
        if (data.plotIds.length >= 1) {
          this.selectedPlotId = data.plotIds[0]
          this.querySnippetData();
        }
      });
  }


  querySnippetData(queryChanged:boolean = false) {
    if (this.snippets.length > 0) {
      this.reloadingSnippets = true;
    }
    
    if (!this.selectedPlotId) {
      return;
    }

    if (queryChanged) {
      this.resetPhotoData();
    }

    const allSnippets$ = this.plotsService.getSnippetsData(this.selectedPlotId, this.productType, this.hideCloudImages);
    allSnippets$.subscribe({
      next: this.handleSnippetsData.bind(this),
      error: this.handleError.bind(this)
    });
  }

  private resetPhotoData() {
    this.snippets = [];
    this.selectedImage = null;
  }

  private handleSnippetsData(data: SnippetsResponse) {
    console.log('received snippets data', data);
    this.reloadingSnippets = false;
    if (this.snippets.length === 0) {
      this.snippets = data.resources;
    } else {
      this.snippets = this.snippets.map(existingImage => {
          const updatedImage = data.resources.find(newImage => newImage.timestamp === existingImage.timestamp);
          return updatedImage && updatedImage.available !== existingImage.available ? updatedImage : existingImage;
        });
    }
    
    const hasUnavailableImages = this.snippets.some(image => !image.available);
    // TODO: uncomment when the queue works
    // if (hasUnavailableImages) {
    //   setTimeout(() => {
    //     this.querySnippetData();
    //   }, 60000); // 1 minute
    // }

  }

  private handleError(error: any) {
    this.reloadingSnippets = false;
    console.error('error in querying the snippets metadata!', error);
    const message = error.error.hasOwnProperty('message') ? error.error.message : error;
    showNotification.next({
      title: 'Error',
      message: `Could not handle the snippets metadata. Error: ${message} Please contact the server administration.`,
      class: 'is-error'
    });
  }

  toggleSelect(image: any) {
    this.selectedImage = this.selectedImage === image ? null : image;
  }


}
