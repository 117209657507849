import {Component, Input, EventEmitter, Output, ViewChild, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {AuthImagePipe} from "../../pipes/auth-image.pipe";
import {FeatherModule} from "angular-feather";
import {CommonModule} from "@angular/common";
import {SwiperDirective} from "../../directives/swiper.directive";
import {SwiperOptions} from "swiper/types";
import { HostListener } from '@angular/core';

//SwiperCore.use([Keyboard, Pagination, Navigation, Scrollbar, Zoom]);

@Component({
  standalone: true,
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  imports: [
    CommonModule,
    AuthImagePipe,
    FeatherModule,
    SwiperDirective
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  styleUrls: ['./image-modal.component.scss']
})
export class ImageModalComponent {

  private _swiper: SwiperDirective | undefined;

  @HostListener('document:keydown.escape', ['$event'])
  handleEscape(event: KeyboardEvent) {
    this.cancel();
  }
  
  
  @ViewChild(SwiperDirective) set swiper(swiper: SwiperDirective) {
    this._swiper = swiper;
    this._swiper.slideTo(this.slide, 0.5, false);
  }

  @Input() photoData!: any[];
  @Input() slide!: number;
  @Output() onClose = new EventEmitter();

  rotated: number = 0;

  swiperOptions: SwiperOptions = {
    keyboard: false,
    navigation: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'fraction'
    },
    scrollbar: true,
    slidesPerView: 1,
    zoom: true
  };

  rotatePhoto(degrees: number) {
    this.rotated = degrees;
  }

  cancel() {
    this.onClose.emit(null);
  }

}
